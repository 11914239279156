
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class TrustPilotReview extends Vue{
@Prop() value!: {name: string; avatar?: string; title:string; message: string; stars: number; link: string; createdAt: Date;}

  private avatarSymbols(name: string){
    const s = name.split(' ')

    return s.length > 1 ? s[0][0] + s[1][0] : s[0][0] + (s[0].length > 1 ? s[0][1] : '')
  }
};
