
import { Vue, Component } from 'vue-property-decorator';
import TrustPilotReview from "@/components/trustpilot/TrustPilotReview.vue";
import TrustPilotRating from "@/components/trustpilot/TrustPilotRating.vue";

@Component({
  components: {
    TrustPilotReview,
    TrustPilotRating
  }
})
export default class Reviews extends Vue{

  private reviews: Array<{name: string; avatar?: string; title:string; message: string; stars: number; link: string; createdAt: Date;}> = [
    {
      name: 'Dima Mahin',
      title: 'Сайт отличный',
      message: 'Сайт отличный, всем советую, быстрая продажа скинов и быстрый перевод на карту либо QIWI, ставлю 5 звёзд, но в начале я был не уверен что меня не заскамят, из за того что у бота был ник anatolich52 с 0 лвл стима, а тау всё отлично',
      link: 'https://www.trustpilot.com/reviews/62239c53361b35c36d5e4c09',
      createdAt: new Date('March 05, 2022'),
      stars: 5,
    },
    {
      name: 'Оля',
      title: 'Рекомендую)))',
      message: 'Хороший сайт! Поддержка отвечает быстро. Выплатили без задержек.',
      link: 'https://www.trustpilot.com/reviews/62261888361b35c36d5fb502',
      createdAt: new Date('March 07, 2022'),
      stars: 5,
    },
    {
      name: 'Марат Кочетов',
      title: 'Очень хороший сайт,отличные цены и…',
      message: 'Очень хороший сайт,отличные цены и быстрый вывод средств,я доволен',
      link: 'https://www.trustpilot.com/reviews/622320bd361b35c36d5de7d5',
      createdAt: new Date('March 05, 2022'),
      stars: 5,
    },
    {
      name: 'Лейк :',
      title: 'Была ошибка',
      message: 'Была ошибка, починили за 2 минуты. Деньги за скин заплатили хорошо.',
      link: 'https://www.trustpilot.com/reviews/6220b8fc445f5107dca70bd9',
      createdAt: new Date('March 03, 2022'),
      stars: 5,
    },
    {
      name: 'Макс Адмиралов',
      title: 'норм сайт.хорошо сделан дизайн скины…',
      message: 'норм сайт.хорошо сделан дизайн скины пришли',
      link: 'https://www.trustpilot.com/reviews/6223b938361b35c36d5e6363',
      createdAt: new Date('March 05, 2022'),
      stars: 2,
    },
    {
      name: 'Loney',
      title: 'Лучший сайт',
      message: 'Лучший сайт, потому что имеет ОТЛИЧНЫЕ цены и моментальный вывод. Продал перчатки и за 1 минуту пришли деньги. Даже если что-то пойдёт не так поддержка всегда поможет! В общем если нужно вывести деньги со стима моментально - это лучший сайт!',
      link: 'https://www.trustpilot.com/reviews/6194ccd0e74f085663ecb11a',
      createdAt: new Date('Nov 17, 2021'),
      stars: 5,
    },
    {
      name: 'Dan Johnson',
      title: 'хороший сервис',
      message: 'имя бота в стиме, конечно, сначала вызвало подозрения, как будто это чей-то личный аккаунт. но я для начала и для подстраховки двинул скин, который особой ценности не представляет и которым я даже и не пользовался – авп "лапки" (всё равно "дикое пламя" есть). 70 грн пришли на счёт моментально',
      link: 'https://www.trustpilot.com/reviews/6114309bf9f4870a2806a5bc',
      createdAt: new Date('Aug 11, 2021'),
      stars: 5,
    },
    {
      name: 'suka blet',
      title: 'Лучший сайт для быстрой продажи скинов)',
      message: 'Отличный сервис, который интуитивно понятен. Цены на ваши скины выше, чем у конкурентов, а вывод моментальный. Однозначно советую',
      link: 'https://www.trustpilot.com/reviews/61fc4297441cb9c2472df11c',
      createdAt: new Date(2022, 1, 4),
      stars: 5,
    },
    {
      name: 'Progamer',
      title: 'Приятный сайт,с приятными ценами',
      message: '',
      link: 'https://www.trustpilot.com/reviews/61ea48a3a16c1e751f6fde5a',
      createdAt: new Date(2022, 0, 21),
      stars: 5,
    },
    {
      name: 'Андрей Елисеев',
      title: 'Отличные цены',
      avatar: 'https://user-images.trustpilot.com/61d4d18fb3a2af0012026b96/73x73.png',
      message: 'Отличные цены, я хочу сказать)',
      link: 'https://www.trustpilot.com/reviews/61d4d199a16c1e751f5f2ce8',
      createdAt: new Date(2022, 0, 5),
      stars: 5,
    },
    {
      name: 'Олег Ерёмин',
      title: 'Моментальное зачисление на карту',
      message: 'Быстрое принятие трейда, моментальное зачисление на карту, однозначно рекомендую продавать тут если на кс маркете барыги готовы купить только за 50% от стоимости.',
      link: 'https://www.trustpilot.com/reviews/61b8bf120430e828ebd27b77',
      createdAt: new Date(2021, 11,13),
      stars: 5,
    },
    {
      name: 'Olya Filkinshtein',
      title: 'Отличный сайт!',
      message: 'Отличный сайт! Быстрая продажа. Деньги приходят моментально. Поддержка отвечает быстро. Рекомендую.',
      link: 'https://www.trustpilot.com/reviews/61adf5620b7cbbcbfe024d5d',
      createdAt: new Date(2021, 11,5),
      stars: 5,
    },
    {
      name: 'Glaedr GG',
      title: 'Лучший сайт для вывода',
      message: 'Очень быстро все сделали, приятные цены, лучшие в своем сегменте!',
      link: 'https://www.trustpilot.com/reviews/619a66a78931a330873205e5',
      createdAt: new Date(2021, 10,20),
      stars: 5,
    },
    {
      name: 'Loney',
      title: 'Лучший сайт',
      message: 'Лучший сайт, потому что имеет ОТЛИЧНЫЕ цены и моментальный вывод. Продал перчатки и за 1 минуту пришли деньги. Даже если что-то пойдёт не так поддержка всегда поможет! В общем если нужно вывести деньги со стима моментально - это лучший сайт!',
      link: 'https://www.trustpilot.com/reviews/6194ccd0e74f085663ecb11a',
      createdAt: new Date(2021, 10,16),
      stars: 5,
    },
    {
      name: 'саша вантап',
      title: 'надежный сервис',
      message: 'быстро продал, деньги пришли моментально поддержка отвечает быстро',
      link: 'https://www.trustpilot.com/reviews/61694376234f1b1fe55ab979',
      createdAt: new Date(2021, 9,14),
      stars: 5,
    },
    {
      name: 'LOli pop',
      title: 'Хороший сервис',
      message: 'Отличный сервис, цены выше чем на других аналогичных услугах. Деньги приходят быстро, сайт работает очень хорошо.',
      link: 'https://www.trustpilot.com/reviews/6168160375069a4da484fda2',
      createdAt: new Date(2021, 9,13),
      stars: 5,
    },
    {
      name: 'Emil Sat',
      title: 'Рекомендую',
      message: 'Рекомендую. Деньги поступили моментально, я дольше искал трейд ссылку и заполнял трейд оффер. Остался очень доволен. Цены на выкуп приемлемые',
      link: 'https://www.trustpilot.com/reviews/616760d575069a4da4848fa1',
      createdAt: new Date(2021, 9,13),
      stars: 5,
    },
    {
      name: 'IOVOI',
      title: 'Продажа керамбита',
      message: 'Продавал керамбит, сначала возникли небольшие неполадки, но оперативность техподдержки меня крайне удивила, средства успешно выведены на карту, сайту можно доверять.',
      link: 'https://www.trustpilot.com/reviews/613e7a8d6223e22118ae8bbf',
      createdAt: new Date(2021, 8,12),
      stars: 5,
    },
    {
      name: 'Dan Johnson',
      title: 'хороший сервис',
      message: 'имя бота в стиме, конечно, сначала вызвало подозрения, как будто это чей-то личный аккаунт. но я для начала и для подстраховки двинул скин, который особой ценности не представляет и которым я даже и не пользовался – авп "лапки" (всё равно "дикое пламя" есть). 70 грн пришли на счёт моментально',
      link: 'https://www.trustpilot.com/reviews/6114309bf9f4870a2806a5bc',
      createdAt: new Date(2021, 7,10),
      stars: 5,
    },
    {
      name: 'Pavel Kolosov',
      title: 'хороший сайт',
      message: 'хороший сайт, выплатили моментально на киви, рекомендую',
      link: 'https://www.trustpilot.com/reviews/60d3aaacf9f4870470e067ed',
      createdAt: new Date(2021, 5,23),
      stars: 5,
    },
  ]
  private reviewsStatsNames = ['Ужасно','Плохо','Средне','Хорошо','Отлично']

private get reviewsStats(){
    const result = [
      0,
      0,
      0,
      0,
      0,
    ];

    this.reviews.forEach((review) => {
      result[Math.ceil(review.stars) - 1] += 1;
    });

    return result;
}
};
